import React from 'react';
import { Link } from 'gatsby'

// styles
// TODO

const Index = () => {
  return (
    <main>
      <nav
        style={{
          borderRight: "solid 1px",
          padding: "1rem",
        }}
      >

        <Link to={`/validator`} style={{ display: "block", margin: "1rem 0" }} >
          validator
        </Link>

        <Link to={`/whitelist`} style={{ display: "block", margin: "1rem 0" }} >
          whitelist
        </Link>

        <Link to={`/whitelistAdmin`} style={{ display: "block", margin: "1rem 0" }} >
          whitelist admin
        </Link>

        <Link to={`/portal/SilkGenarator`} style={{ display: "block", margin: "1rem 0" }} >
          slik genarator
        </Link>
      </nav>
    </main>
  )
}

export default Index
